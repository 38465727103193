import { apiClient } from './client';
import { PinnedMessageModel } from './model/pinnedMessage';

export type CreateParams = {
  messageId: number;
};

export type CreateRes = {
  data: {
    pinned_message: PinnedMessageModel;
  };
};

export async function create(params: CreateParams) {
  const body = {
    message_id: params.messageId,
  };
  return apiClient.post<CreateRes>(`/users/pinned_messages`, body);
}

export type DestroyParams = {
  pinnedMessageId: number;
};

export type DestroyRes = {
  data: {
    status: boolean;
  };
};

export async function destroy(params: DestroyParams) {
  return apiClient.delete<DestroyRes>(
    `/users/pinned_messages/${params.pinnedMessageId}`
  );
}
