import { apiClient } from './client';
import { LockedRecordModel } from './model/lockedRecord';

export type CreateParams = {
  lockableType: string;
  lockableId: number;
};

export type CreateRes = {
  data: {
    lockedRecord: LockedRecordModel;
  };
};

export async function create(params: CreateParams) {
  const body = {
    lockable_type: params.lockableType,
    lockable_id: params.lockableId,
  };
  return apiClient.post<CreateRes>(`/users/locked_records`, body);
}

export type DestroyParams = {
  lockedRecordId: number;
};

export type DestroyRes = {
  data: {
    status: boolean;
    lockedRecordId: number;
  };
};

export async function destroy(params: DestroyParams) {
  return apiClient.delete<DestroyRes>(
    `/users/locked_records/${params.lockedRecordId}`
  );
}
