import React, { useState, useEffect } from 'react';
import './AddTaskForm.scss';
import Button from 'components/atoms/Button';
import { ConstructionModel } from 'api/model/construction';
import { useForm } from 'react-hook-form';

const defaultProps = {
  triggerSubmit: false,
  inputValue: '',
};

type Props = {
  construction: ConstructionModel | null;
  onSubmit: (params: FormData) => void;
  handleCloseForm: () => void;
};

export type FormData = {
  title: string;
};

export default function AddTaskForm(props: Props) {
  const { construction, onSubmit, handleCloseForm } = props;

  const {
    register,
    reset,
    getValues,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const [editTitle, setEditTitle] = useState('');

  const [triggerSubmit, setTriggerSubmit] = useState(false);

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditTitle(event.target.value);
  };

  useEffect(() => {
    if (triggerSubmit && construction) {
      const values = getValues();
      onSubmit(values);
      reset();
    }
  }, [triggerSubmit]);

  return (
    <div className="AddTaskForm">
      <div className="AddTaskForm__input">
        <label>
          <span />
        </label>
        <input
          {...register('title', {
            validate: (value) => value !== '',
          })}
          placeholder="新規作成"
          name="title"
          onChange={handleInput}
          autoFocus
        />
      </div>
      <div className="AddTaskForm__buttons">
        <div className="AddTaskForm__cancel" onClick={() => handleCloseForm()}>
          <span>キャンセル</span>
        </div>
        <Button
          variant="default"
          type="submit"
          onClick={() => setTriggerSubmit(true)}
          disabled={!editTitle}
        >
          新規作成
        </Button>
      </div>
      {errors.title && (
        <div className="AddTaskForm__error">やることを入力してください</div>
      )}
    </div>
  );
}

AddTaskForm.defaultProps = defaultProps;
