import React, { useState, ReactNode } from 'react';
import { idSheetModel } from '../../../api/model/idSheet';
import { onlyDateStringJp } from '../../../libs/date';
import PageTitle from '../../atoms/PageTitle/index';

const defaultProps = {
  idSheets: [],
};

type Props = {
  children?: ReactNode;
  idSheets: idSheetModel[];
} & typeof defaultProps;

export default function IdSheet(props: Props) {
  const { idSheets } = props;
  const [selectedIdSheetIndexes, setSelectedIdSheetIndexes] = useState<
    number[]
  >([]);

  const isLoaded = () => {
    return idSheets.length !== 0;
  };
  const handleAllOpen = () => {
    setSelectedIdSheetIndexes(
      [...Array(idSheets.length)].map((_, index) => index)
    );
  };

  const handleAllClose = () => setSelectedIdSheetIndexes([]);

  return (
    <>
      <PageTitle>確認事項</PageTitle>
      <div id="pageTop" className="container -with-bar">
        <div className="CheckItem">
          {idSheets.length > 0 && (
            <div className="CheckItem__list-header">
              <div onClick={handleAllOpen}>すべて開く</div>
              <div onClick={handleAllClose}>すべて閉じる</div>
            </div>
          )}

          <ul className="CheckItem__list">
            {idSheets.map((idSheet, index) => {
              if (
                idSheet.total_check_items !== 0 &&
                selectedIdSheetIndexes.includes(index)
              ) {
                return (
                  <li
                    key={idSheet.design_step_name}
                    className="-open"
                    onClick={() =>
                      setSelectedIdSheetIndexes(
                        selectedIdSheetIndexes.filter((i) => i !== index)
                      )
                    }
                  >
                    <span className="CheckItem__list-item-inner">
                      <span className="CheckItem__list-label">
                        {idSheet.design_step_name.replace('(ver3)', '')}
                      </span>
                      <span className="CheckItem__list-number">
                        {idSheet.total_completed_check_items}/
                        {idSheet.total_check_items}
                      </span>
                    </span>
                    <div className="CheckItem__list-detail">
                      {isLoaded() &&
                        idSheet.agendas
                          .filter((agenda) => !agenda.ignored)
                          .map((agenda) => {
                            return (
                              <dl key={agenda.name}>
                                <dt>{agenda.name}</dt>
                                {agenda.check_items?.map((checkItem) => {
                                  return checkItem.households
                                    .filter((household) => !household.ignored)
                                    .map((household, index) => {
                                      if (household.checked) {
                                        return (
                                          <dl key={checkItem.name}>
                                            <dd
                                              className={`${
                                                index === 0 && '-construction'
                                              }`}
                                            >
                                              <span
                                                className={`CheckItem__list-check-item ${
                                                  index !== 0 && '-child'
                                                } ${
                                                  !household.canceled &&
                                                  '-checked'
                                                }`}
                                              >
                                                <span className="CheckItem__list-check-inner">
                                                  <span className="CheckItem__list-datail-name">
                                                    {checkItem.name}
                                                  </span>
                                                  <span
                                                    className={`SheetView__list-check-desc-wrap ${
                                                      checkItem.households
                                                        .length === 1
                                                        ? '-household-single'
                                                        : ''
                                                    }`}
                                                  >
                                                    {checkItem.households
                                                      .length !== 1 && (
                                                      <span className="CheckItem__checked-household">
                                                        {household.name}様
                                                      </span>
                                                    )}
                                                    <span className="CheckItem__checked-desc">
                                                      {onlyDateStringJp(
                                                        household.checked_at
                                                      )}{' '}
                                                      確認
                                                    </span>
                                                    {household.rechecked_at && (
                                                      <span className="CheckItem__checked-desc">
                                                        {onlyDateStringJp(
                                                          household.rechecked_at
                                                        )}{' '}
                                                        確認
                                                      </span>
                                                    )}
                                                  </span>
                                                </span>
                                              </span>
                                            </dd>
                                          </dl>
                                        );
                                      }
                                      return (
                                        <dl key={checkItem.name}>
                                          <dd
                                            className={`${
                                              index === 0 && '-construction'
                                            }`}
                                          >
                                            <span
                                              className={`CheckItem__list-check-item ${
                                                index !== 0 && '-child'
                                              }`}
                                            >
                                              <span className="CheckItem__list-datail-name">
                                                {checkItem.name}
                                              </span>
                                            </span>
                                          </dd>
                                        </dl>
                                      );
                                    });
                                })}
                              </dl>
                            );
                          })}
                    </div>
                  </li>
                );
              }
              return (
                idSheet.total_check_items !== 0 && (
                  <li
                    key={idSheet.design_step_name}
                    onClick={() =>
                      setSelectedIdSheetIndexes(
                        selectedIdSheetIndexes.concat(index)
                      )
                    }
                  >
                    <span className="CheckItem__list-item-inner">
                      <span className="CheckItem__list-label">
                        {idSheet.design_step_name.replace('(ver3)', '')}
                      </span>
                      <span className="CheckItem__list-number">
                        {idSheet.total_completed_check_items}/
                        {idSheet.total_check_items}
                      </span>
                    </span>
                  </li>
                )
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}

IdSheet.defaultProps = defaultProps;
