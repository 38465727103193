import axios from 'axios';

const apiClient = axios.create({
  // TODO: ENV ファイル
  baseURL: 'https://api.github.com',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
});
/**
 * API 定義
 *
 */

// params 定義
export type GetGitUserParams = {
  name: string;
};

// res 定義
export type GetGitUserRes = {
  login: string;
};

export async function getGitUser(params: GetGitUserParams) {
  return apiClient.get<GetGitUserRes>(`/users/${params.name}`);
}
