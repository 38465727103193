import PageTitle from 'components/atoms/PageTitle';
import React, { ReactNode, useState, useEffect } from 'react';
import { NotificationSettingModel } from '../../../api/model/notificationSetting';

const defaultProps = {
  notificationSettings: [],
};

type Props = {
  children?: ReactNode;
  notificationSettings: NotificationSettingModel[];
  handleToggleMailFlag: (userEmailId: number, mailType: string) => void;
} & typeof defaultProps;

export default function NotificationSetting(props: Props) {
  const { notificationSettings, handleToggleMailFlag } = props;

  const [currentEmail, setCurrentEmail] =
    useState<NotificationSettingModel | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  useEffect(() => {
    // 初回レンダリング後に、notificationSettingsを取得した場合
    if (notificationSettings.length >= 1 && currentEmail === null) {
      setCurrentEmail(notificationSettings[0]);
    }

    // トグルを変更した場合
    if (notificationSettings.length >= 1) {
      setCurrentEmail(notificationSettings[selectedIndex]);
    }
  }, [notificationSettings]);

  const handleSelectChange = (id: string) => {
    setSelectedIndex(Number(id));
    const currentEmail = notificationSettings[Number(id)];

    if (currentEmail) {
      setCurrentEmail(currentEmail);
    }
  };

  function handleToggle(mailType: string, id?: number) {
    if (id) {
      handleToggleMailFlag(id, mailType);
    }
  }

  return (
    <>
      <PageTitle>通知設定</PageTitle>
      <div className="container -with-bar">
        <div className="NotificationSetting">
          <p className="NotificationSetting__lead">
            個別のメールごとに通知・停止を選択できます
          </p>
          <p className="NotificationSetting__description">
            メールアドレスの追加・変更は
            <br className="u-display-sp" />
            三井ホーム倶楽部の「会員情報の変更」でできます。
          </p>
          <div className="NotificationSetting__inner">
            <div className="Setting__mail-adresss-filter">
              <label>アドレスを選択</label>
              <div className="Setting__select-wrap">
                <select onChange={(e) => handleSelectChange(e.target.value)}>
                  {notificationSettings.map((notificationSetting, index) => (
                    <option value={index} key={notificationSetting.id}>
                      {notificationSetting.address}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <ul className="Setting__list">
              <li>
                <span className="Setting__left">
                  <span className="Setting__label">日次レポート</span>
                  <span className="Setting__description">
                    住まいづくりナビの更新があった場合、前日の内容について配信されるメールです。
                  </span>
                </span>
                <span className="Setting__right">
                  <input
                    className="Btn__switchtgl"
                    id="input01"
                    type="checkbox"
                    checked={
                      currentEmail?.setting.should_send_daily_report_mail ||
                      false
                    }
                    onChange={() =>
                      handleToggle('daily_report', currentEmail?.id)
                    }
                  />
                  <label className="Btn__switchtgl-btn" htmlFor="input01" />
                </span>
              </li>
              <li>
                <span className="Setting__left">
                  <span className="Setting__label">
                    打ち合わせリマインドメール
                  </span>
                  <span className="Setting__description">
                    打合せ予定日3日前に、打合せ内容について配信されるメールです。
                  </span>
                </span>
                <span className="Setting__right">
                  <input
                    className="Btn__switchtgl"
                    id="input02"
                    type="checkbox"
                    checked={
                      currentEmail?.setting
                        .should_send_appointment_remind_mail || false
                    }
                    onChange={() =>
                      handleToggle('appointment_remind', currentEmail?.id)
                    }
                  />
                  <label className="Btn__switchtgl-btn" htmlFor="input02" />
                </span>
              </li>
              <li>
                <span className="Setting__left">
                  <span className="Setting__label">スケジュール更新</span>
                  <span className="Setting__description">
                    カレンダーにてスケジュールが更新された時に配信されるメールです。
                  </span>
                </span>
                <span className="Setting__right">
                  <input
                    className="Btn__switchtgl"
                    id="input03"
                    type="checkbox"
                    checked={
                      currentEmail?.setting.should_send_schedule_mail || false
                    }
                    onChange={() => handleToggle('schedule', currentEmail?.id)}
                  />
                  <label className="Btn__switchtgl-btn" htmlFor="input03" />
                </span>
              </li>
              <li>
                <span className="Setting__left">
                  <span className="Setting__label">掲示板更新</span>
                  <span className="Setting__description">
                    掲示板が更新された時に配信されるメールです。
                  </span>
                </span>
                <span className="Setting__right">
                  <input
                    className="Btn__switchtgl"
                    id="input04"
                    type="checkbox"
                    checked={
                      currentEmail?.setting.should_send_message_mail || false
                    }
                    onChange={() => handleToggle('message', currentEmail?.id)}
                  />
                  <label className="Btn__switchtgl-btn" htmlFor="input04" />
                </span>
              </li>
              <li>
                <span className="Setting__left">
                  <span className="Setting__label">
                    やることリマインドメール
                  </span>
                  <span className="Setting__description">
                    締切日3日前のやることリストについて配信するメールです。
                  </span>
                </span>
                <span className="Setting__right">
                  <input
                    className="Btn__switchtgl"
                    id="input05"
                    type="checkbox"
                    checked={
                      currentEmail?.setting.should_send_task_remind_mail ||
                      false
                    }
                    onChange={() =>
                      handleToggle('task_remind', currentEmail?.id)
                    }
                  />
                  <label className="Btn__switchtgl-btn" htmlFor="input05" />
                </span>
              </li>
              <li>
                <span className="Setting__left">
                  <span className="Setting__label">やること更新</span>
                  <span className="Setting__description">
                    やることリストが更新された時に配信されるメールです。
                  </span>
                </span>
                <span className="Setting__right">
                  <input
                    className="Btn__switchtgl"
                    id="input06"
                    type="checkbox"
                    checked={
                      currentEmail?.setting.should_send_task_mail || false
                    }
                    onChange={() => handleToggle('task', currentEmail?.id)}
                  />
                  <label className="Btn__switchtgl-btn" htmlFor="input06" />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

NotificationSetting.defaultProps = defaultProps;
