import { apiClient } from './client';
import { DesignStepModel } from './model/designStep';

export type FetchRes = {
  data: {
    design_steps: DesignStepModel[];
  };
};

export async function fetch() {
  return apiClient.get<FetchRes>(`/users/design_steps`);
}
