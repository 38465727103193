import React, { useEffect, ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../App/rootReducer';
import { useParams } from 'react-router-dom';
import { getAppointment } from '../appointmentSlice';
import { fetchHouseholds } from '../../household/householdSlice';
import * as Sentry from '@sentry/browser';
import AppointmentDetail from '../../../components/pages/AppointmentDetail';

type Props = {
  children?: ReactNode;
};

export default function AppointmentDetailWithRedux(props: Props) {
  const dispatch = useDispatch<any>();
  const { id } = useParams();

  // TODO: as any良くない
  const appointment = useSelector(
    (state: RootState) => state.appointment.appointment
  ) as any;
  const milestone = useSelector(
    (state: RootState) => state.appointment.milestone
  ) as any;
  const fetching = useSelector(
    (state: RootState) => state.appointment.fetching
  );
  const households = useSelector(
    (state: RootState) => state.household.households
  );

  const construction = useSelector(
    (state: RootState) => state.user.user.login_construction
  );

  useEffect(() => {
    async function f() {
      try {
        await dispatch(getAppointment(Number(id)));
      } catch (error) {
        // Sentry.captureException(error);
      }
    }
    f();
  }, [dispatch, id]);

  useEffect(() => {
    async function f() {
      try {
        await dispatch(fetchHouseholds());
      } catch (error) {
        // Sentry.captureException(error);
      }
    }
    f();
  }, [dispatch]);

  return (
    <>
      <AppointmentDetail
        appointment={appointment}
        milestone={milestone}
        fetching={fetching}
        handedOver4monthsAgo={construction!.handed_over_4months_ago}
        households={households}
        hasUsingHouseholds={appointment?.hasUsingHouseholds}
        ignoreVersionMaster={construction?.ignore_version_master}
      />
    </>
  );
}
