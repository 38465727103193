import { apiClient } from './client';
import { DataFileModel } from './model/dataFile';

export type FetchParams = {
  filterType: string;
};

export type FetchRes = {
  data: {
    data_files: DataFileModel[];
  };
};

export async function fetch(params: FetchParams) {
  return apiClient.get<FetchRes>(
    `/users/data_files?filter_type=${params.filterType}`
  );
}
