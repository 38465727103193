import { apiClient } from './client';
import { HouseholdModel } from './model/household';

export type FetchRes = {
  data: {
    households: HouseholdModel[];
  };
};

export async function fetch() {
  return apiClient.get<FetchRes>(`/users/households`);
}
