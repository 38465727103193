import { apiClient } from './client';
import { AgendaModel } from './model/agenda';

export type GetParams = {
  agendaId: number;
};

export type GetRes = {
  data: {
    agenda: AgendaModel;
  };
};

export async function get(params: GetParams) {
  return apiClient.get<GetRes>(`/users/agendas/${params.agendaId}`);
}
