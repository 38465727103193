import { AppThunk } from '../../App/store';
import { articleReviewCreate, create } from '../../api/review';

export const onSendReview =
  (rating: number, comment: string | null, userId: number): AppThunk =>
  async (dispatch) => {
    try {
      await create({
        rating: rating,
        comment: comment,
        userId: userId,
      });
    } catch (err) {
      throw err;
    }
  };

export const onSendArticleReview =
  (reviewTargetId: number, comment: string | null, userId: number): AppThunk =>
  async (dispatch) => {
    try {
      await articleReviewCreate({
        reviewTargetId: reviewTargetId,
        comment: comment,
        userId: userId,
      });
    } catch (err) {
      throw err;
    }
  };
