import { apiClient } from './client';
import { ArticleModel } from './model/article';

export type GetParams = {
  articleId: number;
};

export type GetRes = {
  data: {
    article: ArticleModel;
  };
};

export async function get(params: GetParams) {
  return apiClient.get<GetRes>(`/users/articles/${params.articleId}`);
}

export type ToggleParams = {
  articleId: number;
  householdId: number | null;
};

export type ToggleRes = {
  data: {
    checked: boolean;
    household_id: number | null;
  };
};

// コラムの確認更新
export async function toggle(params: ToggleParams) {
  const body = {
    article_id: params.articleId,
    household_id: params.householdId,
  };
  return apiClient.post<ToggleRes>(`/users/completed_articles`, body);
}

export type UpdateMaterialParams = {
  articleId: number;
};

export type UpdateMaterialRes = {
  data: {
    completed: boolean;
  };
};

// 部資材コラムの「completion_date:理解しましたをチェックした日」更新
export async function updateMaterial(params: UpdateMaterialParams) {
  return apiClient.patch<UpdateMaterialRes>(
    `/users/material_characteristics/${params.articleId}`
  );
}
