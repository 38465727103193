import React, { ReactNode } from 'react';
import './FloatingServerLabel.scss';

const defaultProps = {};

type Props = {
  children?: ReactNode;
} & typeof defaultProps;

export default function FloatingServerLabel(props: Props) {
  const getLabel = () => {
    switch (process.env.REACT_APP_ENV_NAME) {
      case 'dev':
        return '開発環境';
      case 'edu':
        return '教育環境';
      default:
        return '検証環境';
    }
  };

  return (
    <div id="FloatingServerLabel">
      <p>{getLabel()}</p>
    </div>
  );
}
