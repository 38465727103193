import { apiClient } from './client';
import { TaskModel } from './model/task';

// index
export type FetchRes = {
  data: {
    tasks: TaskModel[];
  };
};

export async function fetch() {
  return apiClient.get<FetchRes>('/users/tasks');
}

// show
export type FetchDetailParams = {
  taskId: number;
};

export type FetchDetailRes = {
  data: {
    task: TaskModel;
  };
};

export async function fetchDetail(params: FetchDetailParams) {
  return apiClient.get<FetchDetailRes>(`/users/tasks/${params.taskId}`);
}

// history
export type FetchHistoryParams = {
  taskId: number;
};

export type FetchHistoryRes = {
  data: {
    tasks: TaskModel[];
  };
};

export async function fetchHistory(params: FetchHistoryParams) {
  return apiClient.get<FetchHistoryRes>(
    `/users/tasks/${params.taskId}/history`
  );
}

// create
export type CreateParams = {
  title: string;
};

export type CreateRes = {
  data: {
    task: TaskModel;
  };
};

export async function create(params: CreateParams) {
  const body = {
    title: params.title,
  };
  return apiClient.post<CreateRes>(`/users/tasks`, body);
}

// update
export type UpdateParams = {
  taskId: number;
  title: string;
  detail: string;
  dueDate: string | null;
  priority: string;
  progressStatus: string;
  sharedStatus: string;
  // colorId: number; 今後追加予定
  trashed: boolean;
};

export type UpdateRes = {
  data: {
    tasks: TaskModel[];
  };
};

export async function update(params: UpdateParams) {
  const body = {
    title: params.title,
    detail: params.detail,
    due_date: params.dueDate,
    priority: params.priority,
    progress_status: params.progressStatus,
    shared_status: params.sharedStatus,
    // color_id: params.colorId, 今後追加予定
    trashed: params.trashed,
  };
  return apiClient.patch<UpdateRes>(`/users/tasks/${params.taskId}`, body);
}

// sort
export type SortParams = {
  taskId: number;
  moveTo: number;
};

export type SortRes = {
  data: {
    task: TaskModel;
  };
};

export async function sort(params: SortParams) {
  return apiClient.patch<SortRes>(`/users/tasks/${params.taskId}/sort`, {
    move_to: params.moveTo,
  });
}

// destroy
export type DestroyParams = {
  taskId: number;
};

export type DestroyRes = {
  data: {
    tasks: TaskModel[];
  };
};

export async function destroy(params: DestroyParams) {
  return apiClient.delete<DestroyRes>(`/users/tasks/${params.taskId}`);
}
