import { apiClient } from './client';
import { CatalogModel } from './model/catalog';

export type FetchRes = {
  data: {
    catalogs: CatalogModel[];
  };
};

export async function fetch() {
  return apiClient.get<FetchRes>(`/users/catalogs`);
}
