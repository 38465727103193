import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetch, get } from '../../api/appointment';
import { AppThunk } from '../../App/store';
import { AppointmentModel } from '../../api/model/appointment';
import { MilestoneModel } from '../../api/model/milestone';
import { ConstructionCardModel } from '../../api/model/userCard';
import { serializeError } from 'serialize-error';

type AppointmentLoaded = {
  appointments: AppointmentModel[];
  milestone: MilestoneModel;
  hasUsingHouseholds: boolean;
  isHome: boolean;
};

type GetAppointmentLoaded = {
  appointment: AppointmentModel;
  milestone: MilestoneModel;
};

type AppointmentState = {
  appointments: AppointmentModel[];
  homeAppointments: AppointmentModel[];
  milestone: null | MilestoneModel;
  construction: null | ConstructionCardModel;
  appointment: null | AppointmentModel;
  loading: boolean;
  fetching: boolean;
  error: any;
  hasUsingHouseholds: boolean;
};

const initialState: AppointmentState = {
  appointments: [],
  homeAppointments: [],
  milestone: null,
  construction: null,
  appointment: null,
  loading: false,
  fetching: true,
  error: null,
  hasUsingHouseholds: false,
};

const appointment = createSlice({
  name: 'appointment',
  initialState,
  reducers: {
    fetchAppointmentsStart(state) {
      state.loading = true;
    },
    fetchAppointmentsSuccess(state, action: PayloadAction<AppointmentLoaded>) {
      const { appointments, milestone, hasUsingHouseholds, isHome } =
        action.payload;
      if (isHome) {
        state.homeAppointments = appointments;
      } else {
        state.appointments = appointments;
      }
      state.milestone = milestone;
      state.hasUsingHouseholds = hasUsingHouseholds;
      state.loading = false;
      state.error = null;
    },
    fetchAppointmentsFailure(state, action: PayloadAction<Error>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    getAppointmentStart(state) {
      state.loading = true;
      state.fetching = true;
    },
    getAppointmentSuccess(state, action: PayloadAction<GetAppointmentLoaded>) {
      const { appointment, milestone } = action.payload;
      state.appointment = appointment;
      state.milestone = milestone;
      state.loading = false;
      state.fetching = false;
      state.error = null;
    },
    getAppointmentFailure(state, action: PayloadAction<Error>) {
      state.error = serializeError(action.payload);
      state.loading = false;
      state.fetching = false;
    },
    resetError(state) {
      state.error = null;
    },
  },
});

export const {
  fetchAppointmentsSuccess,
  fetchAppointmentsStart,
  fetchAppointmentsFailure,
  getAppointmentSuccess,
  getAppointmentStart,
  getAppointmentFailure,
  resetError,
} = appointment.actions;
export default appointment.reducer;

export const fetchAppointments =
  (isHome: boolean): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchAppointmentsStart());
      const res = await fetch({ isHome });
      dispatch(
        fetchAppointmentsSuccess({
          appointments: res.data.data.appointments,
          milestone: res.data.data.milestone,
          hasUsingHouseholds: res.data.data.hasUsingHouseholds,
          isHome,
        })
      );
    } catch (err: any) {
      dispatch(fetchAppointmentsFailure(err));
      throw err;
    }
  };

export const getAppointment =
  (appointmentId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getAppointmentStart());
      const res = await get({ appointmentId: appointmentId });
      setTimeout(
        () =>
          dispatch(
            getAppointmentSuccess({
              appointment: res.data.data.appointment,
              milestone: res.data.data.milestone,
            })
          ),
        1000
      );
    } catch (err: any) {
      dispatch(getAppointmentFailure(err));
      throw err;
    }
  };
