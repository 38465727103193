import React, { useEffect, ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, useTypedSelector } from '../../../App/rootReducer';
import { FileParams } from '../../../api/message';
import { resetNotificationCount } from '../../user/userSlice';
import {
  createMessage,
  updateMessage,
  fetchMessages,
  deleteFile,
  fetchNewMessages,
  deleteMessage,
  searchMessages,
  setPinnedMessage,
  resetPinnedMessage,
} from '../messageSlice';
import {
  createPinnedMessage,
  deletePinnedMessage,
} from '../../pinnedMessage/pinnedMessageSlice';
import * as Sentry from '@sentry/browser';
import Messages from '../../../components/pages/Messages';

type Props = {
  children?: ReactNode;
};

export default function MessageWithRedux(props: Props) {
  const dispatch = useDispatch<any>();

  const construction = useSelector(
    (state: RootState) => state.user.user.login_construction
  );

  // TODO: as any良くない
  const messages = useSelector(
    (state: RootState) => state.message.messages
  ) as any;
  const messageCount = useSelector(
    (state: RootState) => state.message.messageCount
  ) as number;
  const page = useSelector((state: RootState) => state.message.page) as any;
  const getMessagesNum = useSelector(
    (state: RootState) => state.message.getMessagesNum
  ) as any;
  const fetching = useSelector((state: RootState) => state.message.fetching);
  const currentTFilterType = useTypedSelector(
    (state: RootState) => state.message.selectedTab
  );
  const pinnedMessage = useSelector((state: RootState) => state.pinnedMessage);
  const user = useSelector((state: RootState) => state.user.user);

  useEffect(() => {
    async function f() {
      try {
        await dispatch(fetchMessages());
        // 通知リセット
        await dispatch(resetNotificationCount());
      } catch (error) {
        // Sentry.captureException(error);
      }
    }
    f();
  }, [dispatch]);

  useEffect(() => {
    async function f() {
      try {
        if (pinnedMessage.pinnedMessage) {
          await dispatch(setPinnedMessage(pinnedMessage.pinnedMessage));
        }
      } catch (error) {
        throw error;
      }
    }
    f();
  }, [pinnedMessage.pinnedMessage]);

  // 初期作成
  const onSubmitMessage = (body: string, files: object[], status: string) => {
    async function f() {
      const formattedFiles: FileParams[] = [];

      // TODO: anyを修正
      files.forEach((file: any) => {
        if (file.file) {
          formattedFiles.push({
            name: file.file.name,
            url: file.file.type,
            image: file.file,
          });
        }
      });

      try {
        await dispatch(createMessage(body, formattedFiles, status));
        if (status === 'draft') {
          await dispatch(searchMessages(1, status, ''));
        } else {
          await dispatch(fetchMessages());
        }
      } catch (error) {
        // Sentry.captureException(error);
      }
    }
    f();
  };

  // 下書きから更新
  const onUpdateMessage = async (
    messageId: number,
    body: string,
    files: object[],
    status: string
  ) => {
    const formattedFiles: FileParams[] = [];

    try {
      files.forEach((file: any) => {
        if (file) {
          formattedFiles.push({
            fileId: file.fileId ?? '',
            name: file.file?.name ? file.file.name : '',
            url: file.file?.type,
            image: file.file ?? '',
          });
        }
      });
      await dispatch(updateMessage(messageId, body, formattedFiles, status));
      if (status === 'draft') {
        await dispatch(searchMessages(1, status, ''));
      } else {
        await dispatch(fetchMessages());
      }
    } catch (error) {
      throw error;
    }
  };

  const onDeleteFile = (fileId: number, messageId: number) => {
    async function f() {
      try {
        await dispatch(deleteFile(fileId, messageId));
        await dispatch(fetchMessages());
      } catch (error) {
        // Sentry.captureException(error);
      }
    }
    f();
  };

  const onDeleteMessage = (messageId: number, currentTab: string) => {
    async function f() {
      try {
        await dispatch(deleteMessage(messageId));
        if (currentTab === 'draft') {
          await dispatch(searchMessages(1, currentTab, ''));
        } else {
          await dispatch(fetchMessages());
        }
      } catch (error) {
        // Sentry.captureException(error);
      }
    }
    f();
  };

  const onFetchNewMessages = (
    page: number,
    filterType: string,
    searchKeywords?: string
  ) => {
    async function f() {
      try {
        await dispatch(fetchNewMessages(page, filterType, searchKeywords));
      } catch (error) {
        // Sentry.captureException(error);
      }
    }
    f();
  };

  const onChangeSearchMessages = (
    filterType: string,
    searchKeywords?: string
  ) => {
    dispatch(searchMessages(1, filterType, searchKeywords));
  };

  const handleCreatePinnedMessage = async (messageId: number) => {
    async function f() {
      try {
        await dispatch(createPinnedMessage(messageId));
        if (currentTFilterType !== 'posted') {
          await dispatch(searchMessages(1, currentTFilterType, ''));
        }
      } catch (error) {
        throw error;
      }
    }
    f();
  };

  const handleDeletePinnedMessage = async (
    messageId: number,
    pinnedMessageId: number
  ) => {
    async function f() {
      try {
        await dispatch(deletePinnedMessage(pinnedMessageId));
        await dispatch(resetPinnedMessage(messageId));
        if (currentTFilterType !== 'posted') {
          await dispatch(searchMessages(1, currentTFilterType, ''));
        }
      } catch (error) {
        throw error;
      }
    }
    f();
  };

  return (
    <>
      <Messages
        messages={messages}
        messageCount={messageCount}
        page={page}
        getMessagesNum={getMessagesNum}
        fetching={fetching}
        user={user}
        onDelete={(fileId, messageId) => onDeleteFile(fileId, messageId)}
        onDeleteMessage={(messageId, currentTab) =>
          onDeleteMessage(messageId, currentTab)
        }
        onSubmit={(body, files, status) => onSubmitMessage(body, files, status)}
        onUpdate={(messageId, body, files, status) =>
          onUpdateMessage(messageId, body, files, status)
        }
        scrollFetchNewMessages={(page, currentTFilterType, searchKeywords) =>
          onFetchNewMessages(page, currentTFilterType, searchKeywords)
        }
        handedOver4monthsAgo={construction!.handed_over_4months_ago}
        onChangeSearchMessages={onChangeSearchMessages}
        handleCreatePinnedMessage={handleCreatePinnedMessage}
        handleDeletePinnedMessage={handleDeletePinnedMessage}
      />
    </>
  );
}
