import React, { useState, useEffect } from 'react';
import { ConstructionCardModel } from '../../../api/model/userCard';
import { MilestoneModel } from 'api/model/milestone';
import { HouseholdModel } from 'api/model/household';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import HomeSlideItem, {
  Props as ItemProps,
} from '../../molecules/HomeSlideItem';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import './slide.scss';

const defaultProps = {};

export type Props = {
  data: ItemProps[];
  construction: ConstructionCardModel;
  milestone: MilestoneModel;
  households: HouseholdModel[] | null;
  hasUsingHouseholds: boolean;
} & Omit<typeof defaultProps, ''>;

export default function Slide(props: Props) {
  const { data, construction, milestone, households, hasUsingHouseholds } =
    props;

  const [prevIndex, setPrevIndex] = useState(0);
  const [transitionClass, setTransitionClass] = useState('slide');

  // NOTE:
  // - ご契約、お引渡し以外の打合せデータなし、請負金額等確認書が未完了=>「ご契約」
  // - 未来の打合せデータなし、請負金額等確認書が完了=>「お引渡し」
  // - 未来の打合せデータなし、請負金額等確認書が未完了=>最後の打合せ
  // - 未来の打合せデータあり、請負金額等確認書が未完了=>直近の打合せ
  // - 未来の打合せデータあり、請負金額等確認書が完了=>直近の打合せ
  const [currentIndex, setCurrentIndex] = useState(
    milestone.ukekaku_completed
      ? data.length === 3
        ? 1
        : 1
      : data.length === 3
        ? 1
        : 0
  );

  useEffect(() => {
    if (currentIndex !== prevIndex) {
      if (currentIndex < prevIndex) {
        setTransitionClass('rev');
      } else if (currentIndex > prevIndex) {
        setTransitionClass('slide');
      }
      // 少しずらさないとアニメーションが反映されない
      setTimeout(() => setSlideProp(data[currentIndex]), 10);
      setPrevIndex(currentIndex);
    }
  }, [currentIndex, data, prevIndex, transitionClass]);

  const getSelectedAppointment = () => {
    const appointmentData: ItemProps = data[currentIndex];
    return appointmentData;
  };

  // slideProp にセットするとアニメーションが発火する。
  const [slideProp, setSlideProp] = useState(getSelectedAppointment());

  return (
    <div className="Slide">
      <div className="Slide__arrows">
        <div className="Slide__arrowsback">
          {data[currentIndex - 1] && (
            <IconButton
              aria-label="back"
              onClick={() => {
                setCurrentIndex(currentIndex - 1);
              }}
            >
              <ArrowBackIosIcon fontSize="large" />
            </IconButton>
          )}
        </div>
        <div className="Slide__arrowsforward">
          {data[currentIndex + 1] && (
            <IconButton
              aria-label="forward"
              onClick={() => {
                setCurrentIndex(currentIndex + 1);
              }}
            >
              <ArrowForwardIosIcon fontSize="large" />
            </IconButton>
          )}
        </div>
      </div>
      <TransitionGroup>
        <CSSTransition
          appear
          classNames={transitionClass}
          timeout={{ enter: 300, exit: 300 }}
          key={JSON.stringify(slideProp)}
        >
          <div className="Slide__background">
            <HomeSlideItem
              construction={construction}
              {...slideProp}
              milestone={milestone}
              households={households}
              hasUsingHouseholds={hasUsingHouseholds}
            />
          </div>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
}

Slide.defaultProps = defaultProps;
