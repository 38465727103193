import React from 'react';
import { ConstructionCardModel } from '../../../api/model/userCard';
import { MilestoneModel } from 'api/model/milestone';
import { HouseholdModel } from '../../../api/model/household';
import { HouseholdFlagModel } from '../../../api/model/agenda';
import { shortdateStringJp } from '../../../libs/date';
import A from '../../atoms/A';
import HouseholdFlag from '../../atoms/HouseholdFlag';
import { isBefore, startOfDay } from 'date-fns';
import {
  DISPLAY_FILENAME_EXTENSIONS,
  DOWNLOAD_FILE_EXTENSIONS,
} from 'libs/constants';
import { downloadFileFromURL } from 'libs/downloadFileFromUrl';
import { getThumbnail } from 'libs/getThumbnail';

const defaultProps = {
  appointmentType: '',
  appointmentDay: '',
  appointmentPlace: '',
  appointmentTitle: '',
  appointmentTime: '',
  appointmentNote: '',
  households: [],
  agendaList: [],
  recordFiles: [],
  relatedFiles: [],
  construction: {},
  conferenceUrl: '',
};

export type Props = {
  households?: HouseholdModel[] | null;
  agendaList?: Agenda[];
  recordFiles?: File[];
  relatedFiles?: File[];
  construction?: ConstructionCardModel;
  milestone?: MilestoneModel;
  hasUsingHouseholds: boolean;
} & Omit<
  typeof defaultProps,
  'households' | 'agendaList' | 'recordFiles' | 'relatedFiles' | 'construction'
>;

type Agenda = {
  id: number;
  flag: boolean;
  image: string;
  name: string;
  number: string;
  inConstructionProcess: boolean;
  householdFlags: HouseholdFlagModel[];
  ignored: boolean;
};

type File = {
  name: string;
  url: string;
  thumb_url: string;
};

export default function HomeSlideItem(props: Props) {
  const {
    appointmentType,
    appointmentDay,
    appointmentTitle,
    appointmentPlace,
    appointmentTime,
    appointmentNote,
    households,
    agendaList,
    recordFiles,
    relatedFiles,
    construction,
    hasUsingHouseholds,
    conferenceUrl,
  } = props;

  const handleClassNames = (
    householdFlags: HouseholdFlagModel[],
    readArticleFlag: boolean
  ) => {
    // 【多世帯ではない場合】 ignored または 赤flagが表示される場合に`-flag-single`クラスがつく。
    const flag =
      (readArticleFlag && households?.length === (0 || undefined)) ||
      households?.length === 1;
    if (flag && householdFlags.length === 1) return '-flag-single';
    // 【多世帯の場合】 ignored または flagがひとつでも表示される場合に`-flag-bg`クラスがつく。
    if (householdFlags.length !== 0) return '-flag-bg';
    return '';
  };

  const handleFileClick = (
    url: string,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    filename: string
  ) => {
    if (event.target) {
      event.preventDefault();
    }
    if (url.includes('.glb')) {
      event.preventDefault(); // デフォルトのリンククリック動作を停止
      localStorage.setItem('three_d_file_url', url);
      const viewerURL = '/three_d_viewer';
      window.open(viewerURL, '_blank', 'noreferrer'); // 別タブで3DファイルのURLを開く
    } else if (
      DOWNLOAD_FILE_EXTENSIONS.some((extension) => filename.includes(extension))
    ) {
      downloadFileFromURL(url, filename);
    } else {
      window.open(url, '_blank', 'noreferrer');
    }
  };

  const renderAgendaList = () => {
    return (
      <ul className="Home__media-list">
        {agendaList &&
          agendaList.map((agendaItem, key) => (
            <li
              className={handleClassNames(
                agendaItem.householdFlags,
                agendaItem.flag
              )}
              key={key}
            >
              {households &&
                households?.length > 1 &&
                agendaItem.householdFlags?.map((householdFlag) => (
                  <HouseholdFlag colorCode={householdFlag.name} key={key} />
                ))}
              <A to={`/agendas/${agendaItem.id}`} key={agendaItem.id}>
                <img src={agendaItem.image} alt="" />
                <span className="text-name">
                  {agendaItem.name}
                  <span className="text-number">{agendaItem.number}</span>
                </span>
              </A>
            </li>
          ))}
      </ul>
    );
  };
  // 多世帯の場合または参加世帯が子世帯のみの場合、参加世帯を表示する
  const displayHouseholds = () => {
    return (
      hasUsingHouseholds ||
      (households &&
        (households.length > 1 ||
          households[0]?.name !== construction?.house_name))
    );
  };

  return (
    <div className="Home__slide-item -active">
      <div className="Home__slide">
        <div className="Home__slide-item -active">
          <div className="Home__slide-label">
            <div className="Home__slide-label-text">
              <p className="text-date">
                {appointmentType !== 'contracted' &&
                  shortdateStringJp(appointmentDay)}
                {appointmentType === 'contracted' &&
                  construction?.contracted &&
                  shortdateStringJp(appointmentDay)}
              </p>
              <h2 className="text-title">{appointmentTitle}</h2>
            </div>
          </div>
          {appointmentType === 'contracted' && (
            <div>
              <div className="Home__article">
                <div className="Home__inner">
                  <div className="Home__article-body">
                    <p className="Home__article-lead">
                      この度はご契約頂き、
                      <br />
                      誠にありがとうございます。
                    </p>
                    <p>
                      理想のお住まいを一緒につくっていくため引き続き打ち合わせのご協力をお願いいたします。
                    </p>
                    <p className="Home__article-note">
                      ※一部地域、物件は住まいづくりナビを利用できない場合がございますので、担当者にご確認ください。
                    </p>
                  </div>
                </div>
              </div>
              {!construction?.ignoreVersionMaster && (
                <div className="Home__media -agenda -icon-document">
                  <div className="Home__inner">
                    <h3 className="Home__heading -icon-document">
                      事前にお読みください
                    </h3>
                    {renderAgendaList()}
                  </div>
                </div>
              )}
            </div>
          )}
          {appointmentType === 'normal' && (
            <div>
              <div className="Home__mtg">
                <div className="Home__inner">
                  <div className="Home__mtg-desc">
                    <ul className="Home__mtg-desc-list">
                      <li>
                        <span className="Home__mtg-desc-label">時間：</span>
                        <span>{appointmentTime}</span>
                      </li>
                      <li>
                        <span className="Home__mtg-desc-label">場所：</span>
                        <span>{appointmentPlace}</span>
                      </li>
                      {displayHouseholds() && (
                        <li className="Home__flex-box">
                          <span className="Home__mtg-desc-label">
                            参加世帯：
                          </span>
                          <ul>
                            {households?.map((household, index) => {
                              return <li key={index}>{household.name}</li>;
                            })}
                          </ul>
                        </li>
                      )}
                      {!isBefore(
                        new Date(appointmentDay),
                        startOfDay(new Date())
                      ) &&
                        conferenceUrl && (
                          <div>
                            <li>
                              <A
                                href={conferenceUrl}
                                className="Home__mtg-desc-link"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  className="icon-link"
                                  src="/assets/img/schedule/icon/join_conference.svg"
                                  alt="オンライン打合せ"
                                />
                              </A>
                            </li>
                            <li>
                              <span className="Home__mtg-desc-url">
                                {conferenceUrl.replace('https://', '')}
                              </span>
                            </li>
                          </div>
                        )}
                    </ul>
                  </div>
                  <h3 className="Home__heading -icon-check">連絡事項</h3>
                  <div className="Home__mtg-notice">{appointmentNote}</div>
                </div>
              </div>
              <div className="Home__media -attach -icon-file">
                <div className="Home__inner">
                  <h3 className="Home__heading -icon-file">打合せ資料</h3>
                  <ul className="Home__media-list">
                    {recordFiles &&
                      recordFiles.map((record_file) => (
                        <li key={record_file.url}>
                          <A
                            href={record_file.url}
                            onClick={(
                              event: React.MouseEvent<
                                HTMLAnchorElement,
                                MouseEvent
                              >
                            ) =>
                              handleFileClick(
                                record_file.url,
                                event,
                                record_file.name
                              )
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={getThumbnail(
                                record_file.url,
                                record_file.thumb_url
                              )}
                              alt={record_file.name}
                            />
                            {DISPLAY_FILENAME_EXTENSIONS.some((extension) =>
                              record_file.url.includes(extension)
                            ) && (
                              <span className="text-name">
                                {record_file.name}
                              </span>
                            )}
                          </A>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              <div className="Home__media -attach -icon-file">
                <div className="Home__inner">
                  <h3 className="Home__heading -icon-file">その他資料</h3>
                  <ul className="Home__media-list">
                    {relatedFiles &&
                      relatedFiles.map((related_file) => (
                        <li key={related_file.url}>
                          <A
                            href={related_file.url}
                            onClick={(
                              event: React.MouseEvent<
                                HTMLAnchorElement,
                                MouseEvent
                              >
                            ) =>
                              handleFileClick(
                                related_file.url,
                                event,
                                related_file.name
                              )
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={getThumbnail(
                                related_file.url,
                                related_file.thumb_url
                              )}
                              alt={related_file.name}
                            />
                            {DISPLAY_FILENAME_EXTENSIONS.some((extension) =>
                              related_file.url.includes(extension)
                            ) && (
                              <span className="text-name">
                                {related_file.name}
                              </span>
                            )}
                          </A>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              {!construction?.ignoreVersionMaster && (
                <div className="Home__media -agenda -icon-document">
                  <div className="Home__inner">
                    <h3 className="Home__heading -icon-document">
                      事前にお読みください
                    </h3>
                    {renderAgendaList()}
                  </div>
                </div>
              )}
            </div>
          )}
          {appointmentType === 'handovered' && (
            <div>
              <div className="Home__article">
                <div className="Home__inner">
                  <div className="Home__article-body">
                    <p className="Home__article-lead">
                      設計打ち合わせが完了しました。
                      <br />
                      お住まいが完成するまでの間に
                      <br />
                      こちらのご準備をお願いいたします。
                    </p>
                    {construction?.handedOver4monthsAgo && (
                      <p className="Home__handedOvered">
                        お引渡しから4ヶ月が経過しているため、掲示板の投稿など編集はできません。
                        <br />
                        引渡し1年後には利用ができなくなります。
                        <br />
                        お住まいの不具合については
                        <a
                          href="https://www.mitsuihomeclub.com/line-howtouse/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          こちら
                        </a>
                        からお問い合わせください。
                      </p>
                    )}
                  </div>
                </div>
              </div>
              {!construction?.ignoreVersionMaster && (
                <div className="Home__media -agenda -icon-document">
                  <div className="Home__inner">
                    <h3 className="Home__heading -icon-document">
                      お引渡しまでのご準備
                    </h3>
                    <ul className="Home__media-list">
                      {agendaList &&
                        agendaList.map((agendaItem, key) => (
                          <li key={key}>
                            <A
                              to={`/agendas/${agendaItem.id}`}
                              key={agendaItem.id}
                            >
                              <img src={agendaItem.image} alt="" />
                              <span className="text-name">
                                {agendaItem.name}
                                <span className="text-number">
                                  {agendaItem.number}
                                </span>
                              </span>
                            </A>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

HomeSlideItem.defaultProps = defaultProps;
