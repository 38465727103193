import { Collapse } from '@material-ui/core';
import React, { useState } from 'react';
import StarRatingComponent from 'react-star-rating-component';
import ModalBase from '../../atoms/ModalBase';

const defaultProps = {};

export type ModalProps = {
  open: boolean;
  userId: number;
  onClose: (reason: string) => void;
  onSubmitReview: (
    rating: number,
    comment: string | null,
    userId: number
  ) => void;
};

export default function ReviewModal(props: ModalProps) {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [formState, setFormState] = useState(false);
  const [completedReview, setCompletedReview] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const { open, userId, onClose, onSubmitReview } = props;

  const handleClose = (reason: string) => {
    onClose(reason);
  };

  const onStarClick = (newRating: number) => {
    setFormState(true);
    setRating(newRating);
  };

  const send = () => {
    onSubmitReview(rating, comment, userId);
    setCompletedReview(true);
  };

  if (completedReview) {
    return (
      <ModalBase
        onClose={() => handleClose('backdropClick')}
        open={open}
        className="Modal"
      >
        <div className="Modal -review-modal">
          <div className="Modal__content">
            <div className="Modal__nav">
              <p className="Modal__title">ご回答ありがとうございます</p>
            </div>
            <div className="Modal__main">
              <div className="review-line" />
              <div>
                <button
                  className="not-review"
                  onClick={() => handleClose('backdropClick')}
                >
                  閉じる
                </button>
              </div>
            </div>
          </div>
        </div>
      </ModalBase>
    );
  } else {
    return (
      <ModalBase
        onClose={(reason: string) => handleClose(reason)}
        open={open}
        className="Modal"
      >
        <div className="Modal -review-modal">
          <div className="Modal__content">
            <div className="Modal__nav">
              <p className="Modal__title">
                住まいづくりナビの評価をお願いします
              </p>
            </div>
            <div className="Modal__main">
              <div className="rating review-line">
                <StarRatingComponent
                  name="rate1"
                  starCount={5}
                  value={rating}
                  emptyStarColor={'#ccc'}
                  onStarClick={onStarClick}
                />
              </div>
              <div>
                <textarea
                  value={comment}
                  placeholder="コメント記入欄"
                  maxLength={2000}
                  disabled={!formState}
                  onChange={(e) => setComment(e.target.value)}
                />
              </div>
              <div className="terms-container">
                <p className="terms-text">規約に同意のうえ送信してください。</p>
                <button
                  className="terms-details-button"
                  onClick={() => setExpanded(!expanded)}
                >
                  規約詳細
                </button>
              </div>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <p className="terms-text-left">
                  レビューに関わる著作権（日本国著作権法第27条及び第28条所定の権利を含む。）等の一切の権利は当社に帰属します。また、利用者はレビューに関して、著作者人格権その他いかなる権利も行使しないものとします。
                  当社は、レビューの内容を、当該レビューを投稿した利用者に通知することなく、自由に転載、引用、開示、提供、出版、配信その他の方法により、無償で利用することができるものとし、利用者はこれに同意します。
                  レビューを表示するかどうか、その表示期間および当社による保管期間については、当社の裁量で定めることができるものとし、利用者はこれに異議を述べないものとします。
                </p>
              </Collapse>
              <div
                className={`review-line send-review ${formState ? '-on' : ''}`}
              >
                <button
                  className="send-button"
                  disabled={!formState}
                  onClick={() => send()}
                >
                  送信
                </button>
              </div>
              <div>
                <button
                  className="not-review"
                  onClick={() => handleClose('backdropClick')}
                >
                  評価しない
                </button>
              </div>
            </div>
          </div>
        </div>
      </ModalBase>
    );
  }
}

ReviewModal.defaultProps = defaultProps;
